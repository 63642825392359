import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonials.css';

const testimonials = [
    {
        name: 'Srikanth Velaga Chowdhary',
        profilePic: '',
        rating: 5,
        feedback: 'Good Veg food , tastes awesome . Must try their chutneys .',
        initial: 'SV'
    },
    {
        name: 'Vamsi Krishna',
        profilePic: '',
        rating: 4,
        feedback: 'Tiffin is good. Good taste and worthy',
        initial: 'VK'
    },
    {
        name: 'Rameswar Patra',
        profilePic: '',
        rating: 5,
        feedback: 'Great start - have been ordering food and not been disappointed so far.The dosas are crisp and their idlis are good too. They have a good sitting place, you may not get a lot of parking space for cars but ample space to park your bike/ bicycle.',
        initial: 'RP'
    },
    {
        name: 'Priya P',
        profilePic: '',
        rating: 4,
        feedback: 'A newly opened vegetarian South Indian Tiffin outlet located in Botanical Garden road, Kondapur opposite Ghanshyam supermarket. The quality of the food is amazing, we tried sambhar vada (could be improved), onion pesarattu (nice with lots of onion) and masala dosa (hot and crispy with red chutney) which were served with 3 types of chutneys and sambhar. The pricing was surprisingly low (around 40-65 per item). It has only self service with seating option in a minimalistic clean ambience.',
        initial: 'PP'
    }
];

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    };

    // const getInitials = (name) => {
    //     const nameParts = name.trim().split(' ');
    //     console.log("into get initials function",nameParts)
    //     if (nameParts.length === 1) {
    //         console.log("name arr len 1:,",nameParts[0][0].toUpperCase())
    //       return nameParts[0][0].toUpperCase();
    //     } else {
    //         console.log("else:",nameParts[0][0].toUpperCase() + nameParts[nameParts.length - 1][0].toUpperCase())
    //       return nameParts[0][0].toUpperCase() + nameParts[nameParts.length - 1][0].toUpperCase();
    //     }
    //   };

    return (
        <section id="testimonials" className='testimonial-section'>
            <div className='testimonial-parent'>
                <h2 className='testimonial-section'>What Our Customers Say</h2>
                <Slider {...settings} className="testimonials-slider">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial">
                            <div className="testimonial-header">
                                {/* <img src={testimonial.profilePic} alt={testimonial.name} className="profile-pic" /> */}
                                {testimonial.profilePic ? (
                                    <img
                                        src={testimonial.profilePic}
                                        alt={testimonial.initial}
                                        className="profile-pic"
                                        loading="lazy"
                                    />
                                    ) : (
                                    <div className="testimonial__initials">
                                        {testimonial.initial}
                                    </div>
                                    )}
                                <div className="testimonial-info">
                                    <h3>{testimonial.name}</h3>
                                    <div className="rating">
                                        {[...Array(5)].map((_, i) => (
                                            <span key={i} className={`star ${i < testimonial.rating ? 'filled' : ''}`}>&#9733;</span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <p>{testimonial.feedback}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default Testimonials;
