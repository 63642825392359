import React from 'react';
import './Footer.css';
import { FaInstagram, FaGoogle } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h2>Mee Palle Ruchulu</h2>
          <p>Authentic South Indian Delicacies</p>
        </div>
        <div className="footer-social-icons">
          <a href="https://www.instagram.com/meepalleruchulu?igsh=MTN5d29lYXVjdDRnMQ==" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://g.page/meepalleruchulu" target="_blank" rel="noopener noreferrer">
            <FaGoogle />
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Mee Palle Ruchulu. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
