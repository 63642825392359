import React, { useState } from 'react';
import './ContactUs.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const googleFormActionURL = 'https://docs.google.com/forms/d/e/1FAIpQLSdnmFQ13cYXrGwYUWO4XywDytGKv-0rWE70-RVCyEiunVBMiQ/formResponse'; // Replace with your actual Google Form action URL

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('entry.1023666767', formData.name); // Replace YOUR_NAME_ENTRY_ID with the corresponding Google Form entry ID
    formDataToSubmit.append('entry.911953452', formData.phone); // Replace YOUR_PHONE_ENTRY_ID with the corresponding Google Form entry ID
    formDataToSubmit.append('entry.1116179421', formData.message); // Replace YOUR_MESSAGE_ENTRY_ID with the corresponding Google Form entry ID

    fetch(googleFormActionURL, {
      method: 'POST',
      body: formDataToSubmit,
      mode: 'no-cors',
    })
      .then(() => {
        alert('Your message has been sent successfully!');
        setFormData({
          name: '',
          phone: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error('Error submitting the form:', error);
      });
  };

  return (
    <section className="contact-us" id="contact">
      <div className="contact-content">
        <h2 className="contact-heading">Contact Us</h2>
        <p className="contact-description">
          Have any questions or want to give us feedback? We'd love to hear from you! Fill out the form below, and we'll get back to you as soon as possible.
        </p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-submit">Submit</button>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;

