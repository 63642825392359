import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-page">

      <section className="about-story">
      <h1 className="about-heading">About Mee Palle Ruchulu</h1>
        <p className="about-description">
          Mee Palle Ruchulu was established by a group of friends who are all into software engineering. Driven by a desire to explore life and take risks, we decided to start this venture alongside our corporate jobs. Our motto is to build fast, experiment fast, and grow fast.
        </p>
        <p className="about-description">
          We are passionate about using technology to solve our customers' problems regarding food. We are constantly seeking new ideas and innovations in the food business. Stay tuned and watch this space for more updates!
        </p>
        <p className="about-description">
          We also aim to make the food ordering process fun and engaging, rather than a heavy decision-making task. Your dining experience should be as enjoyable as the food itself!
        </p>
      </section>

      <div className='journey-section'>
        <h2 className="page-title">Our Journey</h2>
        <div className="timeline">
            <div className="timeline-item">
            <div className="timeline-date">9th September 2023</div>
            <div className="timeline-content">
                <h3>Launch</h3>
                <p>We officially opened our doors to the public.</p>
            </div>
            </div>

            <div className="timeline-item1">
            <div className="timeline-date1">November 2023</div>
            <div className="timeline-content">
                <h3>Online Presence</h3>
                <p>We established our online presence with a website and social media.</p>
            </div>
            </div>

            <div className="timeline-item">
            <div className="timeline-date">February 2024</div>
            <div className="timeline-content">
                <h3>Version 2</h3>
                <p>We remodified our menu to bring more variety and excitement to our customers.</p>
            </div>
            </div>

            <div className="timeline-item1">
            <div className="timeline-date1">March 2024</div>
            <div className="timeline-content">
                <h3>Served 50K+ Customers</h3>
                <p>A major milestone - we served over 50,000 happy customers.</p>
            </div>
            </div>

            <div className="timeline-item">
            <div className="timeline-date">June 2024</div>
            <div className="timeline-content">
                <h3>Served 1 Lac+ Customers</h3>
                <p>We crossed the 1 lac customer mark, thanks to your support!</p>
            </div>
            </div>

            <div className="timeline-item1">
            <div className="timeline-date1">Current (August 2024)</div>
            <div className="timeline-content">
                <h3>Flying High with New Concepts</h3>
                <p>Continuing to innovate and serve our customers with fresh ideas.</p>
            </div>
            </div>
        </div>
      </div>
      

      <section className="about-call-to-action">
        <h2 className="about-subheading">Join Us on This Journey</h2>
        <p className="about-description">
          We’d love to hear your thoughts and ideas on how we can improve. If you have any suggestions, or if you’re interested in partnerships or collaborations, please don’t hesitate to reach out!
        </p>
        <p className="about-description">
          Together, we can continue to innovate and create something truly special in the food industry.
        </p>
      </section>
    </div>
  );
};

export default AboutPage;
