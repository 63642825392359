import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import MenuPage from './components/MenuPage';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

import './App.css';

function App() {
    return (
        <div className="app__bg">
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/menu" element={<MenuPage />} />
                    <Route path="/contact" element={<ContactUs />} />
                </Routes>
                <Footer />
            </Router>
            
        </div>
    );
}

export default App;
