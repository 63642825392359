import React from 'react';
// import Masonry from 'react-masonry-css';
import Lottie from 'lottie-react';
import deliveryBoyAnimation from '../assets/delivery-animation2.json';

import menuImg from '../assets/Menu1.png';
import './MenuPage.css';
import { SiSwiggy, SiZomato } from 'react-icons/si';
import Magicpin from '../assets/magicpin.png'


const MenuPage = () => {

    return (
        <div className='menu-category'>
            <div className='menu-flex-box'>
                <div className='dine-in-menu-block'>
                    <h2 className='menu-title'>Our Dine In Menu</h2>
                    <p className='menu-content'>We welcome you to please visit our restaurant and experience the fresh, hot, live dishes made in front of you.</p>
                    <div className="menu-image-container">
                        <img src={menuImg} className='menu-image'alt='Dine In Menu' loading="lazy"/>
                        {/* <div className="image-overlay"></div> */}
                    </div>
                </div>

                {/* Divider */}
                <div className="divider"></div>

                <div className='platform-links-container'>
                    <h2 className='menu-title1'>Online Delivery Options</h2>
                    <p className='menu-content1'>
                        We are also available on the online platforms like Swiggy, Zomato and Magicpin (ONDC) for your convenience.
                    </p>
                    <div className="platform-links">
                        <a href="https://www.swiggy.com/direct/brand/448501?source=swiggy-direct&subSource=generic" target="_blank" rel="noopener noreferrer">
                        <SiSwiggy size={120} color="#fc8019" className='Swiggy_Zomato_Icon'/>
                        </a>
                        <a href="https://link.zomato.com/xqzv/rshare?id=53610264305633bd" target="_blank" rel="noopener noreferrer">
                        <SiZomato size = {120} color="#cb202d" className='Swiggy_Zomato_Icon'/>
                        </a>
                        <a href="https://magicpin.in/Hyderabad/Kondapur/Restaurant/Mee-Palle-Ruchulu/store/15c0c45/" target="_blank" rel="noopener noreferrer">
                        <img src={Magicpin} className='magicpin-logo' color="#ff5733" alt="magicpin" loading="lazy"/>
                        </a>
                    </div>
                    <p className='menu-content1'>
                        Please click on the icons above to order now.
                    </p>
                </div>
            </div>

            <div className="lottie-animation-container">
                <Lottie animationData={deliveryBoyAnimation} loop={true} className='lottie-animation'/>
            </div>

            {/* Google form poll */}
            <div className="poll">
                <h2>Vote for the Next Item on Our Menu</h2>
                <div className="iframe-container">
                    <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLScRP1llBNdPF5CVNwoizAgY2YbGCrhFOI_JA4jSR1KeJwDtDw/viewform?usp=sf_link"
                    width="640"
                    height="800"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                    title="Poll"
                    >
                    Loading…
                    </iframe>
                </div>
            </div>

        </div>
    );
};

export default MenuPage;
