import React from 'react';
import IntroSection from './IntroSection';
import Testimonials from './Testimonials';
import StorySection from './StorySection';
import MenuSection from './MenuSection';
import HomeDelivery from './HomeDelivery';
import ContactUs from './ContactUs';
import './HomePage.css';


import idli from '../assets/idli_final.jpg';
import vada from '../assets/vada_final.jpg';
import mysoreBonda from '../assets/Mysore_Bonda.jpg';
import upma from '../assets/Upma.jpg';
import mirchiBajji from '../assets/Mirchi_Bajji.jpg';
import nelloreBonda from '../assets/Nellore_Bonda.jpg';
import masalaDosa from '../assets/Masala_Dosa.jpg';
import onionDosa from '../assets/Onion_Dosa.jpg';
import utthapam from '../assets/Utthapam.jpg';
import manyMore from '../assets/manyMore.jpg';

import balayyaDosa from '../assets/Balayya_Dosa.jpg';
import hulkDosa from '../assets/Hulk_Dosa.jpg';
import poori from '../assets/Poori.jpg';
import gheePongal from '../assets/Ghee_Pongal.jpg';
import upmaPesarattu from '../assets/Upma_Pesarattu.jpg';
import ugganiBajji from '../assets/Uggani_bajji.jpg';

const HomePage = () => {

      const menuItems = [
        {
            name: 'Idli (3 pieces)',
            image: idli,
            description: 'Soft, steamed rice cakes.',
            nutrition: ['Protein: Moderate', 'Low Fat', 'Easily Digestible', 'Carbohydrates: High'],
            cookingTime: '20-25 min',
          },
          {
            name: 'Vada (3 pieces)',
            image: vada,
            description: 'Crispy, deep-fried lentil donuts.',
            nutrition: ['Protein: High', 'Rich in Fiber', 'Energy-Dense', 'Good Source of Iron'],
            cookingTime: '30-35 min',
          },
          {
            name: 'Mysore Bonda (4 pieces)',
            image: mysoreBonda,
            description: 'Deep-fried, crispy yogurt batter.',
            nutrition: ['High Carbohydrates', 'Source of Calcium', 'Probiotics', 'Satisfying Snack'],
            cookingTime: '25-30 min',
          },
          {
            name: 'Upma',
            image: upma,
            description: 'Savory, spiced semolina dish.',
            nutrition: ['Protein: Moderate', 'Low Fat', 'Rich in Fiber', 'Energy-Dense'],
            cookingTime: '15-20 min',
          },
          {
            name: 'Mirchi Bajji (3 pieces)',
            image: mirchiBajji,
            description: 'Spicy, deep-fried stuffed chili.',
            nutrition: ['High Carbohydrates', 'Vitamin C: High', 'Energy-Dense', 'Satisfying Snack'],
            cookingTime: '20-25 min',
          },
          {
            name: 'Nellore Bonda (8 pieces)',
            image: nelloreBonda,
            description: 'Crispy, spiced potato fritters.',
            nutrition: ['High Carbohydrates', 'Rich in Potassium', 'Energy-Dense', 'Satisfying Snack'],
            cookingTime: '25-30 min',
          },
          {
            name: 'Masala Dosa',
            image: masalaDosa,
            description: 'Crisp dosa with spiced potato filling.',
            nutrition: ['High Carbohydrates', 'Protein: Moderate', 'Fiber: High', 'Energy-Dense'],
            cookingTime: '20-25 min',
          },
          {
            name: 'Onion Dosa',
            image: onionDosa,
            description: 'Crisp dosa with caramelized onions.',
            nutrition: ['High Carbohydrates', 'Protein: Moderate', 'Rich in Fiber', 'Source of Antioxidants'],
            cookingTime: '20-25 min',
          },
          {
            name: 'Utthapam',
            image: utthapam,
            description: 'Thick, savory pancake with toppings.',
            nutrition: ['Protein: Moderate', 'Rich in Fiber', 'High Carbohydrates', 'Source of Vitamins'],
            cookingTime: '20-25 min',
          },
          {
            name: 'Many More ...',
            image: manyMore,
            description: 'Several other unique items as well',
            nutrition: ['Protein: Moderate', 'Rich in Fiber', 'High Carbohydrates', 'Source of Vitamins'],
            cookingTime: '20-30 min',
          }
      ];

      const chefRecommendations = [
        {
            name: 'Balayya Dosa',
            image: balayyaDosa,
            description: 'Ghee Roasted dosa with palli podi, onions, chilies.',
            nutrition: ['High in Flavor', 'Rich in Fat', 'Source of Protein', 'Energy-Dense'],
            cookingTime: '20-25 min',
          },
          {
            name: 'Hulk Dosa',
            image: hulkDosa,
            description: 'Green leafy vegetable dosa.',
            nutrition: ['High in Vitamins', 'Rich in Fiber', 'Low Fat', 'Antioxidant-Rich'],
            cookingTime: '20-25 min',
          },
          {
            name: 'Ghee Pongal',
            image: gheePongal,
            description: 'Creamy rice and lentil dish with ghee.',
            nutrition: ['High in Fat', 'Rich in Protein', 'Easily Digestible', 'Energy-Dense'],
            cookingTime: '25-30 min',
          },
          {
            name: 'Poori',
            image: poori,
            description: 'Deep-fried wheat bread.',
            nutrition: ['High Carbohydrates', 'Rich in Fiber', 'Energy-Dense', 'Satisfying Snack'],
            cookingTime: '15-20 min',
          },
          {
            name: 'Ghee Upma Pesarattu',
            image: upmaPesarattu,
            description: 'Ghee upma with green gram dosa.',
            nutrition: ['High in Protein', 'Rich in Fiber', 'Low Fat', 'Energy-Dense'],
            cookingTime: '25-30 min',
          },
          {
            name: 'Uggani Bajji',
            image: ugganiBajji,
            description: 'Spiced puffed rice with fritters.',
            nutrition: ['High Carbohydrates', 'Rich in Fiber', 'Low Fat', 'Energy-Dense'],
            cookingTime: '20-25 min',
          }
      ]

    return (
        <div id="home" className="home-page">
                {/* <CarouselBanner /> */}
            <IntroSection />
            <MenuSection title="From Our Kitchen to Your Table" items={menuItems} />
            <MenuSection title="Chef's Recommendations" items={chefRecommendations} />
            <HomeDelivery />

            <Testimonials />
            <StorySection />
            <ContactUs />
            <section id="contact">
                <div className="map-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.991799798344!2d78.35257457547492!3d17.460106583439533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9324d34daba7%3A0x11fa15b7f34461e7!2sMee%20Palle%20Ruchulu!5e0!3m2!1sen!2sin!4v1723299821571!5m2!1sen!2sin"
                        width="100%" 
                        height="450" 
                        allowFullScreen
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Maps">
                        </iframe>

                </div>
            </section>
        </div>
    );
};

export default HomePage;
