import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { FaInstagram, FaGoogle } from 'react-icons/fa';
import logo from '../assets/mprlogo3.png'; // Make sure the path to the logo is correct

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  console.log("screen size right now is:",window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar__logo">
        <Link to="/" className="navbar__logo-link">
            <img className="navbar__image" src={logo} alt="Mee Palle Ruchulu Logo" loading="lazy"/>
            <span className="navbar__header">Mee Palle Ruchulu</span>
        </Link>
      </div>
      <div className="social-icons">
        <p>Follow Us:</p>
        <a href="https://www.instagram.com/meepalleruchulu?igsh=MTN5d29lYXVjdDRnMQ==" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href="https://g.page/meepalleruchulu" target="_blank" rel="noopener noreferrer">
          <FaGoogle />
        </a>
      </div>
        <div className="hamburger" onClick={toggleMobileMenu}>
            <span className="hamburger-bar"></span>
            <span className="hamburger-bar"></span>
            <span className="hamburger-bar"></span>
        </div>
      <ul className={`navbar__menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <li className="navbar-item">
          <Link to="/" className="navbar__item" onClick={() => setIsMobileMenuOpen(false)}>Home</Link>
        </li>
        <li className="navbar-item">
          <Link to="/menu" className="navbar__item" onClick={() => setIsMobileMenuOpen(false)}>Menu</Link>
        </li>
        <li className="navbar-item">
          <Link to="/about" className="navbar__item" onClick={() => setIsMobileMenuOpen(false)}>About</Link>
        </li>
        <li className="navbar-item">
          <Link to="/contact" className="navbar__item" onClick={() => setIsMobileMenuOpen(false)}>Contact Us</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
