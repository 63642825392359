import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './IntroSection.css';
import bannerImage1 from '../assets/bannerImage1.png';

const IntroSection = () => {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const handleWhatsAppClick = () => {
        setShowModal(true);
      };
    
      const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
      };
    
      const handleSendMessage = () => {
        let message = '';
        switch (selectedOption) {
          case 'timings':
            message = 'I would like to know the timings of the restaurant.';
            break;
          case 'bulkOrder':
            message = 'I need to place a bulk order.';
            break;
          case 'delivery':
            message = 'I have a query regarding online delivery.';
            break;
          case 'availability':
            message = 'I would like to know the availability of an item.';
            break;
          case 'other':
            message = 'I need to talk regarding something else.';
            break;
          default:
            message = '';
        }
    
        // WhatsApp API link with your business number
        const whatsappLink = `https://wa.me/9381764045?text=${encodeURIComponent(message)}`;
        window.open(whatsappLink, '_blank');
        setShowModal(false); // Close the modal
      };

      const handleMenuClick = () => {
        // Redirect to the menu page
        navigate('/menu');
      };


        const handleCloseModal = (event) => {
            if (event.target.className === 'modal') {
            setShowModal(false);
            }
        };

  return (
    <section className="intro-section">
      <div className="intro-content">
        <h1 className="intro-heading">Savor the Authentic Flavors of South India</h1>
        <p className="intro-subheading">
          Experience the rich culinary traditions of Andhra and Telangana, right at your doorstep.
        </p>
        <p className="intro-description">
          At Mee Palle Ruchulu, we bring you the finest South Indian Tiffins, crafted with love and authenticity. 
          From the classic Balayya Dosa to the innovative Hulk Dosa, our menu is a celebration of flavor and tradition.
        </p>
        <div className="intro-buttons">
           <button onClick={handleWhatsAppClick} className="talk-to-us-button">
                <FaWhatsapp size={20} style={{ marginRight: '10px' }} />
                Talk to Us
            </button>
            <button onClick={handleMenuClick} className="explore-menu-button">
                Explore Menu
            </button>

            {showModal && (
                <div className="modal" onClick={handleCloseModal}>
                <div className="modal-content">
                    <p>Hi There, What do you want to talk to us about?</p>
                    <div className="options-container">
                    <label>
                        <input
                        type="radio"
                        value="timings"
                        checked={selectedOption === 'timings'}
                        onChange={handleOptionChange}
                        />
                        Timings of the restaurant
                    </label>
                    <label>
                        <input
                        type="radio"
                        value="bulkOrder"
                        checked={selectedOption === 'bulkOrder'}
                        onChange={handleOptionChange}
                        />
                        I need a bulk order
                    </label>
                    <label>
                        <input
                        type="radio"
                        value="delivery"
                        checked={selectedOption === 'delivery'}
                        onChange={handleOptionChange}
                        />
                        Regarding online delivery
                    </label>
                    <label>
                        <input
                        type="radio"
                        value="availability"
                        checked={selectedOption === 'availability'}
                        onChange={handleOptionChange}
                        />
                        Availability of an item
                    </label>
                    <label>
                        <input
                        type="radio"
                        value="other"
                        checked={selectedOption === 'other'}
                        onChange={handleOptionChange}
                        />
                        I need to talk regarding something else
                    </label>
                    </div>
                    <div className="button-container">
                    <button onClick={handleSendMessage} className="send-message-button">
                        <FaWhatsapp size={16} style={{ marginRight: '10px' }} />
                        Send Message
                    </button>
                    <button onClick={() => setShowModal(false)} className="close-modal-button">
                        Close
                    </button>
                    </div>
                </div>
                </div>
            )}
        </div>
      </div>
      <div className="intro-image">
        <img src={bannerImage1} alt="Delicious South Indian Dishes" loading="lazy"/>
      </div>
    </section>
  );
};

export default IntroSection;
