import React from 'react';
import './MenuSection.css';

const MenuSection = ({ title, items }) => (
  <section className="menu-section">
    <h2 className="section-title">{title}</h2>
    <div className="grid-container">
      {items.map((item, index) => (
        <div className="card" key={index}>
          <div className="card-inner">
            <div className="card-front">
              {item.image ? 
                <img src={item.image} alt={item.name} className="item-image" loading="lazy"/>
                : <span className='span-front'/>
              }
              <h3 className="item-name">{item.name}</h3>
            </div>

            <div className="card-back">
                <h3>{item.name}</h3>
                <p className="description">{item.description}</p>
                <p><strong>Nutritional Highlights:</strong></p>
                <ul>
                  {item.nutrition.map((nutrient, i) => (
                    <li key={i}>{nutrient}</li>
                  ))}
                </ul>
                <p className='cookingTime'><strong>Cooking Time:</strong> {item.cookingTime}</p>
              </div>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default MenuSection;
