import React from 'react';
import './HomeDelivery.css';

const HomeDelivery = () => {

  return (
    <section className="home-delivery">
      <div className="home-delivery__content">
        <div className='home-delivery__desc_container'>
            <h2 className="home-delivery__heading">Home Delivery & Much More...</h2>
            <p className="home-delivery__description">
            Enjoy the authentic flavors of South India from the comfort of your home. Our prompt and reliable home delivery service ensures that your favorite dishes are just a call or click away. Fresh, hot, and ready to be savored. We also do party orders and catering services. Please reach out to us.
            </p>
        </div>
      </div>
    </section>
  );
};

export default HomeDelivery;
