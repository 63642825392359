import React from 'react';
import './StorySection.css';

const StorySection = () => {
  return (
    <section className="story-section">
      <div className="story-content">
        <h2 className="story-heading">Our Story</h2>
        <p className="story-description">
          At Mee Palle Ruchulu, our journey began with a simple passion: to bring the authentic flavors of South India to your plate. From the rich traditions of Andhra and Telangana, we create dishes that celebrate the culinary heritage of the region. Every meal we serve is a story, crafted with care and a commitment to quality.
        </p>
        <p className="story-description">
          Join us on this flavorful journey and experience the true essence of South Indian cuisine, right here in Hyderabad.
        </p>
      </div>
    </section>
  );
};

export default StorySection;
